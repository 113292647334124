
import { Vue, Component } from 'vue-property-decorator';
import CarsTypeMappingPopup from '@/modules/cars-category-manager/components/cars-category-mapping.popup.vue';

@Component({
    components: {
        CarsTypeMapping: CarsTypeMappingPopup,
    },
})
export default class CarMappingModalPage extends Vue {}

